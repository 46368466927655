
import { useMarketStore } from '~/stores/market'
import { useGrosseryStore } from '~/stores/grossery'

export default {
  name: 'MPLandingCartItems',
  setup() {
    const grosseryStore = useGrosseryStore()
    const marketStore = useMarketStore()
    return {
      grosseryStore,
      marketStore,
    }
  },
  data() {
    return {
      isShow: true,
    }
  },
  computed: {
    totalAmount() {
      return this.marketStore.cart ? this.marketStore.cart.total_amount : 0
    },
    cartItems() {
      const products = []
      this.marketStore.cart?.sellers.forEach(partner => {
        partner.line_items.forEach(item => {
          products.push(item)
        })
      })
      return products
    },
  },
  mounted() {
    if (!this.grosseryStore.grossery_merchant_uid) {
      this.grosseryStore.GET_GROCERY_MERCHANT_GUID()
    }
    this.grosseryStore.updateTypeGrosseryCartMode('ALL_PRODUCTS')
  },
  methods: {
    checkUrlImg(url) {
      return url || '/images/no-image-found.webp'
    },
    getLink(product) {
      return this.localePath({
        name: 'product-slug',
        params: {
          slug: product?.catalog_id || product?.product_id || product?.id,
        },
        query: {
          source: 'main',
          item_list_name: product?.src_item_list_name || null,
          product_index: product?.src_item_list_index || 0,
        },
      })
    },
  },
}
